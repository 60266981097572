@font-face {
  font-family: "Inter";
  src: local("Inter"),
    url(./assets/fonts/Inter/Inter-Regular.woff) format("woff");
}

@font-face {
  font-family: "Inria";
  src: local("Inria"),
    url(./assets/fonts/Inria_Sans/InriaSans-Regular.woff) format("woff");
}

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  background: rgb(24 24 27);
  scroll-behavior: smooth;
}

@supports (-webkit-touch-callout: none) {
  /* CSS for iOS devices */
  header,
  .bg-ios-fix {
    background-attachment: scroll !important;
    background-size: cover !important;
    background-position: center center;
  }
}

@supports not (-webkit-touch-callout: none) {
  /* CSS for other than iOS devices */
  header,
  .bg-ios-fix {
    background-attachment: fixed !important;
    background-size: cover !important;
  }
}

.inria {
  font-family: "Inria", sans-serif;
}

/*
 * Fix bug for swiper slider, when click on previous button,
 * the tiles are glittering one on the other 
 * CF https://stackoverflow.com/questions/57125366/swiper-slider-transition-bug 
 */
.swiper-slide.swiper-slide-active,
.swiper-slide.swiper-slide-duplicate-active {
  transform: scale(1) !important;
}

.swiper-wrapper {
  padding-bottom: 40px;
}

.footer-icon path {
  fill: white;
}

/**
 * Custom map marker
 */
.pin {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: rgb(214, 11, 11);
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
}
.pin:after {
  content: "";
  width: 14px;
  height: 14px;
  margin: 8px 0 0 8px;
  background: #fff;
  position: absolute;
  border-radius: 50%;
}

.bounce {
  animation-name: bounce;
  animation-fill-mode: both;
  animation-duration: 1s;
}

.pulse {
  background: transparent;
  border-radius: 50%;
  height: 14px;
  width: 14px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 11px 0px 0px -12px;
  transform: rotateX(55deg);
  z-index: -2;
}
.pulse:after {
  content: "";
  border-radius: 50%;
  height: 20px;
  width: 20px;
  position: absolute;
  margin: -3px 0 0 -3px;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
  box-shadow: 0 0 1px 2px rgb(222 192 163);
  animation-delay: 1.1s;
}

@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

@keyframes bounce {
  0% {
    opacity: 0;
    transform: translateY(-2000px) rotate(-45deg);
  }

  60% {
    opacity: 1;
    transform: translateY(30px) rotate(-45deg);
  }

  80% {
    transform: translateY(-10px) rotate(-45deg);
  }

  100% {
    transform: translateY(0) rotate(-45deg);
  }
}
